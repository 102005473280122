<template>
  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-warehouse-editAll',query:{id:0,companyId:0,companyName:''}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchListAll"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
      >

        <!-- Columns -->
        <template #cell(warehouse_id)="data">
          #{{ data.item.warehouse_id }}
        </template>


        <!-- Column: creator checker -->

        <template #cell(check_time)="data">
          {{ toTime(data.item.check_time) }}
        </template>

        <template #cell(cqr_id)="data">
          {{ data.item.ext['departmentName'] }}
        </template>


        <template #cell(checker)="data">
          {{ getCodeLabel('user', data.item.checker) }}
        </template>

        <template #cell(status)="data">
          {{ getCodeLabel('warehouse_status', data.item.status) }}
        </template>

        <template #cell(sales_type)="data">
          {{ getCodeLabel('store_sales_type', data.item.sales_type) }}
        </template>

        <template #cell(state)="data">
          <b-link
              v-if="data.item.status===4"
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state===1?1:0)}`"
            >
              {{ getCodeLabel('state', data.item.state === 1 ? 1 : 0) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(warehouse_rent)="data">
          {{
            isEmpty(data.item.ext.warehouseRent) ? '' :
                (
                    isEmpty(data.item.ext.warehouseRent.rent_price) ? '' : (data.item.ext.warehouseRent.rent_price) + '元 / '
                )
          }}
          {{
            isEmpty(data.item.ext.warehouseRent) ? '' :
                (
                    (data.item.ext.warehouseRent.unit_amount) + getCodeLabel('warehouse_rent_unit', data.item.ext.warehouseRent.unit_rule) + ' / '
                )

          }}
          {{
            isEmpty(data.item.ext.warehouseRent) ? '' :
                (
                    (data.item.ext.warehouseRent.period_amount) + getCodeLabel('warehouse_rent_period', data.item.ext.warehouseRent.period_rule)
                )
          }}
        </template>


        <!-- Column: Actions   style=" margin-left: -47px;"-->
        <template #cell(actions)="data">
          <div v-if="data.item.state!==2">
            <b-dropdown
                variant="link"
                no-caret
                left="-47px "
            >

              <!--     style=" margin-left: 30px;"        -->
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>


              <!--    -->
              <div v-if="data.item.status!==3">
                <b-dropdown-item
                    :to="{ name: 'apps-warehouse-editAll', query: { id: data.item.id ,companyId:data.item.company_id,companyName:companyName} }">
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">编辑</span>
                </b-dropdown-item>
              </div>

              <div v-if="data.item.status===2||data.item.status===5">
                <b-dropdown-item @click="submit(data.item)">
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">提交</span>
                </b-dropdown-item>
              </div>

              <b-dropdown-item :to="{ name: 'apps-warehouselocation-list', query: {id: data.item.id} }">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">库位</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-warehousecharge-list', query: { id: data.item.id} }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">负责人管理</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-warehouserelation-list', query: { id: data.item.id} }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">第三方仓库关联管理</span>
              </b-dropdown-item>

              <!--            <b-dropdown-item   :to="{ name: 'apps-warehousestatement-list', query: { id: data.item.id,companyId:data.item.company_id} }">-->
              <!--              <feather-icon icon="FileTextIcon" />-->
              <!--              <span class="align-middle ml-50">账期设置</span>-->
              <!--            </b-dropdown-item>-->

              <!--       style=" margin-left: -16px;"     -->
              <b-dropdown-item :to="{ name: 'apps-warehousehead-list', query: { id: data.item.id} }">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">抬头设置</span>
              </b-dropdown-item>


              <b-dropdown-item v-b-modal.modal-warehouse-log @click="changeModifyLog(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">修改日志</span>
              </b-dropdown-item>

              <div v-if=" user.role_id===12 ||user.role_id===14">
                <b-dropdown-item v-b-modal.modal-company-qrcode @click="changeModifyLog(data.item)">
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">关联收款码</span>
                </b-dropdown-item>
              </div>

            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
          id="modal-company-qrcode"
          ok-only
          ok-title="确认"
          @ok="onContactCode"
          cancel-title="取消"
          centered
          size="lg"
          :title="'关联销售收款码'"
      >
        <company-code-select ref="onContactCodeSelect"></company-code-select>
      </b-modal>

      <b-modal
          id="modal-warehouse-log"
          hide-footer
          centered
          size="xl"
          title="修改日志"
      >
        <modify-log-list :objectTypeProduct="2" :productid="id"></modify-log-list>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, getCodeLabel, toTime, getCodeColor, isEmpty} from '@core/utils/filter'
import warehouseUseList from './warehouseUseList'
import companyStore from "@/views/apps/company/companyStore";
import warehouseStore from './warehouseStore'
import {useRouter} from '@core/utils/utils'
import ModifyLogList from "@/views/apps/modifylog/ModifyLogList";
import CompanyCodeSelect from "@/views/apps/companyqrcode/select/CompanyQrcodeListSelect";
import modifylogStore from "@/views/apps/modifylog/modifylogStore";
import {useToast} from "vue-toastification/composition";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BAlert,
    ModifyLogList,
    CompanyCodeSelect
  },
  data() {
    return {
      id: 0,
      companyName: "",
      companyInformation: {},
      selected: [],
      user: {},
    }
  },
  props: {
    company_id: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    changeState: function (data) {
      if (data.state === 1) {
        const state = 2
        store.dispatch('warehouse/state', {id: data.id, state: state}).then(res => {
          data.state = state
        })
      }

      if (data.state === 2) {
        const state = 1
        store.dispatch('warehouse/state', {id: data.id, state: state}).then(res => {
          data.state = state
        })
      }


    },
    getSelected: function () {
      return this.selected
    },
    onRowSelected(items) {
      this.selected = items
    },
    changeModifyLog(data) {
      this.id = data.warehouse_id
    },

  },
  setup(props) {
    // Register module
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('modifylog')) store.registerModule('modifylog', modifylogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('modifylog')) store.unregisterModule('modifylog')
    })

    const router = useRouter()
    const toast = useToast()


    const getCompany = function () {
      store.dispatch('company/view', {id: this.id}).then(res => {
        this.companyInformation = res.data.data
      })
    }

    if (router.route.value.query.id == undefined) {
      router.route.value.query.id = props.company_id

    }
    const submit = function (data) {
      let status = 3
      let state = 0
      store.dispatch('warehouse/submit', {id: data.id, state: state, status: status}).then(res => {
        if (res.data.code === 0) {
          toast.success("操作成功")
          refetchData()
        }
      })
    }

    const onContactCode = function () {
      if (this.$refs.onContactCodeSelect.getSelected().length > 0) {
        let result = this.$refs.onContactCodeSelect.getSelected()[0]
        let cqrId = result.cqr_id
        let warehouseId = this.id
        store.dispatch('warehouse/contactCompanyQCode', {warehouseId: warehouseId, cqrId: cqrId}).then(res => {
          if (res.data.code === 0) {
            toast.success("添加成功")
            this.refetchData()
          }
        })

      } else {
        toast.error("选择出现错误,请重新选择！")
        this.refetchData()
      }
    }

    const {
      searchList,
      searchListAll,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveWarehouseTypeCode,
      resolveStateCode,
    } = warehouseUseList()

    return {
      searchList,
      searchListAll,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      isEmpty,

      // Filter
      avatarText,

      // UI
      resolveWarehouseTypeCode,
      resolveStateCode,
      getCompany,
      getCodeLabel, toTime, getCodeColor, submit, toast,
      onContactCode
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    this.companyName = this.$route.query.companyName || ""
    this.getCompany()
    const userData = getUserData()
    this.user = userData
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
